<template
  v-for="(item,i) in items.value"
  :key="i"
>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="750px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span class="headline">   {{ item.id !== null ? "Modifier la communication" : "Nouvelle communication" }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="item.title"
                    type="text"
                    class="pa-0 ma-0"
                    label="Titre"
                    clearable
                    :rules="[rules.title]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-file-input
                    v-model="file"
                    accept=".png, .jpeg, .jpg"
                    class="pa-0 ma-0"
                    show-size
                    counter
                    :show-size="false"
                    :counter="false"
                    label="Fichier image"
                    :loading="isDialogVisible"
                    :rules="rules.highlight_image"
                    @change="upload"
                  ></v-file-input>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="item.with_push_notif"
                    class="pa-0 ma-0"
                    :items="with_push_notifs"
                    item-text="text"
                    item-value="value"
                    label="Avec notification push"
                    type="text"
                    :rules="[rules.with_push_notif]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="border-danger"
                >
                  <label style="font-size: 16px">Saisir le contenu</label>
                  <editor-content
                    class="editor"
                    :editor="editor"
                    :rules="[rules.content]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="border-danger"
                >
                  <v-file-input
                    v-model="file_pdf"
                    accept=".pdf"
                    class="pa-0 ma-0"
                    show-size
                    counter
                    :show-size="false"
                    :counter="false"
                    label="Sélectionner un PDF"
                    :loading="isDialogVisible"
                    :rules="rules.communication_pdf"
                    @change="uploadPdf"
                  ></v-file-input>
                </v-col>
                </v-col>
              </v-row>
              <v-row>
                <v-col

                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-skeleton-loader
                    :loading="urlLoading"
                    type="list-item-two-line"
                  >
                    <v-list-item
                      title="Title"
                      subtitle="Subtitle"
                      lines="two"
                      rounded
                    ></v-list-item>
                  </v-skeleton-loader>
                  <div v-for="(url, index) in item.links">
                    <v-row>
<!--                      <v-col
                        cols="12"
                        sm="6"
                        md="2"
                      >
                        <v-select
                          v-model="item.links[index].prefix"
                          item-text="text"
                          item-value="value"
                          class="pa-0 ma-0"
                          label="Préfix"
                          type="text"
                          :items="prefixLinks"
                          :rules="[rules.urls[index].url]"
                        ></v-select>
                      </v-col>-->
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="item.links[index].url"
                          class="pa-0 ma-0"
                          label="Lien"
                          type="url"
                          placeholder="exemple: https://youtu.be/L-wNrr74Xgs"
                          clearable
                          :rules="[rules.urls[index].url]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="item.links[index].label"
                          class="pa-0 ma-0"
                          label="libellé du Lien"
                          :append-icon="mdiPlusCircle"
                          :append-outer-icon="mdiMinusCircle"
                          type="url"
                          clearable
                          :rules="[rules.urls[index].label]"
                          @click:append-outer="removefield(index)"
                          @click:append="addfield"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              {{ item.id !== null ? "Modifier" : "Enregistrer" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  computed, getCurrentInstance, ref, watch,
} from '@vue/composition-api'
import {
  mdiEyeOffOutline, mdiEyeOutline, mdiPlusCircle, mdiCloseCircleOutline, mdiMinusCircle,
} from '@mdi/js'
import { notify } from '@core/utils/notification'

import {
  Editor, EditorContent, EditorMenuBar, Paragraph,
} from 'tiptap'
import {
  Bold, HardBreak, Heading, Italic, Link, Image, Underline,
} from 'tiptap-extensions'
import store from '@/store'

export default {

  name: 'CommunicationForm',

  components: {
    EditorContent,
    EditorMenuBar,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    object: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const color = ref(null)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const urlLoading = ref(false)
    const errors = ref('')
    const items = ref([])
    const isAlertVisible = ref(false)
    const file = ref(null)
    const file_pdf = ref(null)

    const rules = {
      title: true,
      content: true,
      highlight_image: [true],
      communication_pdf: [true],
      with_push_notif: true,
      links: true,
      urls: [
        { url: true, label: true },
      ],
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    watch(() => props.object, newObject => {
      // eslint-disable-next-line no-unused-expressions
      if (newObject.id !== null) {
        urlLoading.value = true
        rules.urls.pop()
        store.state.link.field.communication_id = newObject.id
        store.dispatch('link/getLinks', { page: 1, field: store.state.link.field }).then(() => {
          store.getters['link/links'].forEach(link => {
            newObject.links.push(
              {
                id: link.id,
                url: link.url,
                label: link.label,
                prefix: link.url.split('://')[0],
                surfix: link.url.split('://')[1],
              },
            )
            rules.urls.push({ url: true, label: true })
          })
          urlLoading.value = false
        })
      }
    })

    const editor = new Editor({
      content: item.value.content,
      extensions: [
        new Bold(),
        new Italic(),
        new Link(),
        new HardBreak(),
        new Heading(),
        new Image(),
        new Paragraph(),
        new Heading({
          levels: [1, 2, 3],
        }),
        new Underline(),
      ],
    })
    const instance = getCurrentInstance()

    const close = () => {
      isAlertVisible.value = false
      rules.title = true
      rules.content = true
      rules.highlight_image = [true]
      rules.with_push_notif = true
      rules.links = [true]

      // rules.urls = []
      rules.urls.push({ url: true, label: true })
      instance.emit('clickout')
    }

    watch(() => props.dialog, dialog => {
      // eslint-disable-next-line no-unused-expressions
      dialog || close()
    })
    watch(() => props.object, newValue => {
      editor.setContent(item.value.content)
    })
    const addfield = () => {
      item.value.links.push({ url: null })
      rules.urls.push({ url: true, label: true })
    }

    const removefield = index => {
      if (item.value.links.length > 1) {
        rules.urls.splice(index, 1)
        if (item.value.links[index].id !== undefined) {
          store.dispatch('link/deleteLink', item.value.links[index]).then(() => {
            item.value.links.splice(index, 1)
          })
        } else item.value.links.splice(index, 1)
      }
    }

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      /*for (let i = 0; i < item.value.links.length; i++){
        item.value.links[i].url = `https://${item.value.links[i].surfix}`
      }*/
      item.value.content = editor.getHTML()
      if (item.value.id !== null) {
        store.dispatch('communication/updateCommunication', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          instance.emit('clickout')
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.title = error.response.data.errors.title !== undefined ? error.response.data.errors.title[0] : true
            rules.content = error.response.data.errors.content !== undefined ? error.response.data.errors.content[0] : true
            rules.highlight_image = error.response.data.errors.highlight_image !== undefined ? error.response.data.errors.highlight_image : [true]
            rules.with_push_notif = error.response.data.errors.with_push_notif !== undefined ? error.response.data.errors.with_push_notif[0] : true
            rules.links = error.response.data.errors.links !== undefined ? error.response.data.errors.links[0] : true
            for (let i = 0; i < rules.urls.length; i++) {
              const link = `links.${i}.url`
              const label = `links.${i}.label`
              rules.urls[i].url = error.response.data.errors.hasOwnProperty(link) !== false ? error.response.data.errors[link][0] : true
              rules.urls[i].label = error.response.data.errors.hasOwnProperty(label) !== false ? error.response.data.errors[label][0] : true
            }
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      } else {
        store.dispatch('communication/addCommunication', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          instance.emit('clickout')
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.title = error.response.data.errors.title !== undefined ? error.response.data.errors.title[0] : true
            rules.content = error.response.data.errors.content !== undefined ? error.response.data.errors.content[0] : true
            rules.highlight_image = error.response.data.errors.highlight_image !== undefined ? error.response.data.errors.highlight_image : [true]
            rules.with_push_notif = error.response.data.errors.with_push_notif !== undefined ? error.response.data.errors.with_push_notif[0] : true
            rules.links = error.response.data.errors.links !== undefined ? error.response.data.errors.links[0] : true
            for (let i = 0; i < rules.urls.length; i++) {
              const link = `links.${i}.url`
              const label = `links.${i}.label`
              rules.urls[i].url = error.response.data.errors.hasOwnProperty(link) !== false ? error.response.data.errors[link][0] : true
              rules.urls[i].label = error.response.data.errors.hasOwnProperty(label) !== false ? error.response.data.errors[label][0] : true
            }
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      }
    }

    const upload = event => {
      const formData = new FormData()
      if (event) {
        isDialogVisible.value = true
        formData.append('image', event)
        store.dispatch('quality/uploadFile', { model: 'communication', field: formData }).then(response => {
          item.value.highlight_image = store.state.quality.file
          notify(true, true, response.message)
          isDialogVisible.value = false
        }).catch(error => {
          rules.highlight_image = error.response.data.errors.image !== undefined ? error.response.data.errors.image : true
          message.value = error.response.data.message
          isAlertVisible.value = true
          notify(false, true, error.response.data.message)
          isDialogVisible.value = false
        })
      }
    }

    const uploadPdf = event => {
      const formData = new FormData()
      if (event) {
        isDialogVisible.value = true
        formData.append('document', event)
        store.dispatch('quality/uploadFileCommunicationPdf', { model: 'communication', field: formData }).then(response => {
          item.value.communication_pdf = store.state.quality.fileCommunicationPdf
          notify(true, true, response.message)
          isDialogVisible.value = false
        }).catch(error => {
          rules.communication_pdf = error.response.data.errors.image !== undefined ? error.response.data.errors.image : true
          message.value = error.response.data.message
          isAlertVisible.value = true
          notify(false, true, error.response.data.message)
          isDialogVisible.value = false
        })
      }
    }

    const addImage = () => {
      const url = window.prompt('URL')

      if (url) {
        editor.chain().focus().setImage({ src: url }).run()
      }
    }

    return {
      prefixLinks: [
        { text: 'http', value: 'http' },
        { text: 'https', value: 'https' },
      ],
      addImage,
      urlLoading,
      editor,
      file,
      file_pdf,
      upload,
      uploadPdf,
      close,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      mdiPlusCircle,
      mdiMinusCircle,
      mdiCloseCircleOutline,
      submitForm,
      form,
      addfield,
      removefield,
      date,
      menu,
      picker,
      menuref,
      item,
      color,
      items,
      with_push_notifs: [
        { text: 'OUI', value: true },
        { text: 'NON', value: false },
      ],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.editor {
  background-color: #FFF;
  border: 3px solid #0D0D0D;
  border-radius: 0.75rem;
  color: #0D0D0D;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 16rem;
  max-height: 16rem;

  &__header {
     align-items: center;
     background: #0d0d0d;
     border-bottom: 3px solid #0d0d0d;
     border-top-left-radius: 0.25rem;
     border-top-right-radius: 0.25rem;
     display: flex;
     flex: 0 0 auto;
     flex-wrap: wrap;
     padding: 0.25rem;
   }

  &__content {
     flex: 1 1 auto;
     overflow-x: hidden;
     overflow-y: auto;
     padding: 1.25rem 1rem;
     -webkit-overflow-scrolling: touch;
   }

  &__footer {
     align-items: center;
     border-top: 3px solid #0D0D0D;
     color: #0D0D0D;
     display: flex;
     flex: 0 0 auto;
     flex-wrap: wrap;
     font-size: 12px;
     font-weight: 600;
     justify-content: space-between;
     padding: 0.25rem 0.75rem;
     white-space: nowrap;
   }

/* Some information about the status */
  &__status {
   align-items: center;
   border-radius: 5px;
   display: flex;

  &::before {
     background: rgba(#0D0D0D, 0.5);
     border-radius: 50%;
     content: ' ';
     display: inline-block;
     flex: 0 0 auto;
     height: 0.5rem;
     margin-right: 0.5rem;
     width: 0.5rem;
   }

  &--connecting::before {
     background: #616161;
   }

  &--connected::before {
     background: #B9F18D;
   }
}

  &__name {
    button {
      background: none;
      border: none;
      border-radius: 0.4rem;
      color: #0D0D0D;
      font: inherit;
      font-size: 12px;
      font-weight: 600;
      padding: 0.25rem 0.5rem;

    &:hover {
       background-color: #0D0D0D;
       color: #FFF;
     }
    }
  }
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0D0D0D;
  border-right: 1px solid #0D0D0D;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0D0D0D;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}

/* Basic editor styles */
.ProseMirror {
> * + * {
  margin-top: 0.75em;
}

ul,
ol {
  padding: 0 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

pre {
  background: #0D0D0D;
  border-radius: 0.5rem;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;

code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}
}

mark {
  background-color: #FAF594;
}

img {
  height: auto;
  max-width: 100%;
}

hr {
  margin: 1rem 0;
}

blockquote {
  border-left: 2px solid rgba(#0D0D0D, 0.1);
  padding-left: 1rem;
}

hr {
  border: none;
  border-top: 2px solid rgba(#0D0D0D, 0.1);
  margin: 2rem 0;
}

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

li {
  align-items: center;
  display: flex;

> label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

> div {
  flex: 1 1 auto;
}
}
}
}
</style>
